import React from "react"
import { Flex, Heading, Text, Box, useBreakpoint } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    background: { base: "#E8EBFE", md: "#E8EBFE", lg: "transparent" },
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    px: "24px",
    height: { base: "100%", md: "100%", lg: "489px" },
    pt: { base: "40px", md: "40px", lg: "0px" },
  },
  "left-box": {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    width: "400px",
    mt: { base: "24px", md: "24px", lg: "0px" },
  },

  heading: {
    fontWeight: "bold",
    color: "#1B263F",
    fontSize: { base: "24px", md: "24px", lg: "48px" },
    lineHeight: { base: "32px", md: "32px", lg: "56px" },
  },
  text: {
    color: "#1B263F",
    fontSize: "15px",
    textAlign: { base: "center", md: "center", lg: "left" },
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    marginTop: { base: "16px", md: "16px", lg: "20px" },
  },
  image: {
    ml: { base: "0px", md: "0px", lg: "26px" },
    height: { base: "auto", md: "auto", lg: "200px" },
    width: { base: "100%", md: "100%", lg: "500px" },
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
    marginTop: "32px",
  },
}

const Hero = ({ Image, MobileImage, title, content }) => {
  const bp = useBreakpoint()
  const isMobile = React.useMemo(() => ["sm", "md"].includes(bp), [bp])
  const StaticImage = React.useMemo(
    () => (isMobile ? MobileImage : Image),
    [isMobile, MobileImage, Image]
  )

  return (
    <Flex sx={styles.root}>
      <ChevronDownIcon sx={styles.icon} />
      <Flex sx={styles["left-box"]}>
        <Heading sx={styles.heading}>{title}</Heading>
        <Text sx={styles.text}>{content}</Text>
      </Flex>
      {StaticImage && (
        <Box sx={styles.image}>
          <StaticImage />
        </Box>
      )}
    </Flex>
  )
}
export default Hero

import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"

const styles = {
  flex: {
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: { base: "327px", md: "327px", lg: "658px" },
    height: { base: "104.36px", md: "104.36px", lg: "210px" },
    marginTop: "80px",
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#595C5F",
    textAlign: "left",
  },
  "text-box": {
    width: { base: "327px", md: "327px", lg: "665px" },
    height: { base: "144px", md: "144px", lg: "72px" },
    marginTop: "24px",
  },
}

const CareersDescription = () => {
  return (
    <Flex sx={styles.flex}>
      <Box sx={styles.image}>
        <StaticImage
          alt="Employees talking"
          src="../../images/careers/careers-img.png"
          placeholder="blurred"
        />
      </Box>
      <Box sx={styles["text-box"]}>
        <Text sx={styles.text}>
          We are building an incredible track record of enabling healthcare
          providers to deliver higher quality and more cost-effective care. We
          love working with one another and are looking for people who share
          these values and beliefs to join us:
        </Text>
      </Box>
    </Flex>
  )
}

export default CareersDescription

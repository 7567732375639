import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"

const styles = {
  box: {
    width: { base: "327px", md: "327px", lg: "658px" },
    height: { base: "120px", md: "120px", lg: "230px" },
    marginTop: "80px",
    marginBottom: "229px",
  },
  flex: {
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#595C5F",
    lineHeight: "24px",
    fontStyle: "italic",
  },
}

const EqualOpportunity = () => {
  return (
    <Flex sx={styles.flex}>
      <Box sx={styles.box}>
        <Text sx={styles.text}>
          Avant-garde Health is an equal opportunity employer and does not
          discriminate in employment on the basis of race, color, religion, sex
          (including pregnancy and gender identity), national origin, political
          affiliation, sexual orientation, marital status, disability, genetic
          information, age, membership in an employee organization, retaliation,
          parental status, military service, or other non-merit factor.
        </Text>
      </Box>
    </Flex>
  )
}

export default EqualOpportunity

import React from "react"
import { Heading, Box, Flex, Text } from "@chakra-ui/react"

const styles = {
  box: {
    marginBottom: "161px",
    width: { base: "375px", md: "375px", lg: "656px" },
    height: { base: "441px", md: "441px", lg: "344px" },
  },
  "top-box": {
    marginTop: "40px",
  },
  "bottom-box": {
    marginTop: "40px",
    borderBottom: "1px solid #E0E0E0",
  },
  flex: {
    flexDirection: "column",
    alignItems: "center",
  },

  heading: {
    fontWeight: 700,
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    color: "#1B263F",
    marginTop: "89px",
    marginBottom: { base: "24px", md: "24px", lg: "40px" },
  },
  "inner-heading": {
    fontSize: { base: "18px", md: "18px", lg: "32px" },
    lineHeight: "40px",
    color: "#1B263F",
  },
  "inner-heading-h3": {
    fontSize: { base: "20px", md: "20px", lg: "32px" },
    fontWeight: { base: 500, md: 500, lg: 700 },
    color: "#1B263F",
  },
  text: {
    fontWeight: 400,
    fontSize: "15px",
    marginTop: "8px",
  },
  "bottom-text": {
    fontWeight: 400,
    fontSize: "15px",
    marginTop: "8px",
    marginBottom: "81px",
  },
}

const WorkingWithUs = () => {
  return (
    <Flex sx={styles.flex}>
      <Box sx={styles.box}>
        <Heading as="h1" sx={styles.heading}>
          Working with Us
        </Heading>
        <Box sx={styles["top-box"]}>
          <Heading as="h3" sx={styles["inner-heading"]}>
            Location
          </Heading>
          <Text sx={styles.text}>
            We are located in Boston, MA. We are primarily looking for people
            who will be based in our office, but are open to considering
            extraordinary people based elsewhere.
          </Text>
        </Box>
        <Box sx={styles["bottom-box"]}>
          <Heading as="h3" sx={styles["inner-heading-h3"]}>
            Want to Join our Team?
          </Heading>
          <Text sx={styles["bottom-text"]}>
            E-mail us with the position title in the subject line. Please
            include a description of your skills, expertise, relevant
            qualifications and attach your resume. We look forward to meeting
            with you!
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}

export default WorkingWithUs

import React from "react"
import { Heading, Box, Flex, Text } from "@chakra-ui/react"

const styles = {
  flex: {
    flexDirection: "column",
    alignItems: "center",
  },
  box: {
    borderBottom: "1px solid #E0E0E0",
    height: { base: "200px", md: "200px", lg: "200" },
    width: { base: "327px", md: "327px", lg: "658px" },
    marginTop: { base: "23px", md: "23px", lg: "80px" },
  },
  heading: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#1B263F",
    marginBottom: "24px",
  },
  "heading-box": {
    marginBottom: "24px",
  },
  "link-box": {
    marginBottom: "24px",
  },
}
const NoOpenPositions = () => {
  return (
    <Flex sx={styles.flex}>
      <Box sx={styles.box}>
        <Heading as="h4" sx={styles["heading-box"]}>
          Open Positions
        </Heading>
        <Box sx={styles["link-box"]}>
          <Text sx={{ color: "#595C5F" }}>
            Unfortunately we don’t have any open positions at this time.
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}

export default NoOpenPositions

import React from "react"
import { Box, Text } from "@chakra-ui/react"

const styles = {
  box: {
    display: "flex",
    alignItems: "center",
    padding: "1em 0em 1em 0em",
    marginTop: "2em",
    margin: "0 auto",
    width: { base: "100%", md: "100%", lg: "50%" },
  },
  "inner-box": {
    paddingLeft: "1.5em",
    marginbottom: "54px",
  },
  content: {
    fontSize: "16px",
    color: "#595C5F",
  },
  image: {
    width: { base: "64px", md: "64px", lg: "90px" },
    height: { base: "64px", md: "64px", lg: "90px" },
    minW: { base: "64px", md: "64px", lg: "90px" },
    minH: { base: "64px", md: "64px", lg: "90px" },
  },

  title: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#595C5F",
  },
}

const CareersAttributes = ({ Image, title, content }) => {
  return (
    <Box sx={styles.box}>
      <Box sx={styles.image}>
        <Image />
      </Box>
      <Box sx={styles["inner-box"]}>
        <Text sx={styles.title}>{title}</Text>
        <Text sx={styles.content}>{content}</Text>
      </Box>
    </Box>
  )
}

export default CareersAttributes

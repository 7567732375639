import React from "react"
import get from "lodash/get"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Box } from "@chakra-ui/react"
import Hero from "../components/careers/hero"
import CareersDescription from "../components/careers/description"
import CareersAttributes from "../components/careers/attributes"
import WorkingWithUs from "../components/careers/working-with-us"
import OpenPositions from "../components/careers/open-positions"
import EqualOpportunity from "../components/careers/equal-opportunity"
import NoOpenPositions from "../components/careers/no-open-positions"

const CareersIndex = props => {
  const positions = get(props, "data.allContentfulJobPost.nodes")
  const noOpenPositions = positions => positions.length === 0

  return (
    <Layout location={props.location} title={"Careers"}>
      <Hero
        title="Careers at Avant-garde Health"
        content="Do you want to be part of a team backed by leading VCs that is transforming health care delivery for decades to come?"
        Image={() => (
          <StaticImage
            src="../images/careers/careers-bg.png"
            alt="Careers Hero Image"
            placeholder="Careers Hero Image"
          />
        )}
        MobileImage={() => (
          <StaticImage
            src="../images/careers/mobile-careers-bg.png"
            alt="Contact Us at Avant-garde Health"
            placeholder="blurred"
          />
        )}
      />

      <CareersDescription />
      <Box
        sx={{
          px: "24px",
          marginTop: { base: "40px", md: "40px", lg: "101px" },
        }}
      >
        <CareersAttributes
          Image={() => (
            <StaticImage
              src="../images/careers/rocket-emoji.png"
              alt="Rocket emoji"
              placeholder="blurred"
            />
          )}
          title="Mission Driven"
          content="You are excited to improve how health care is delivered across the world."
        />

        <CareersAttributes
          Image={() => (
            <StaticImage
              src="../images/careers/eyes-emoji.png"
              alt="Eyes emoji"
              placeholder="blurred"
            />
          )}
          title="See Things As They Could Be"
          content="You love to create the future and are not going to be limited by how things are done today."
        />

        <CareersAttributes
          Image={() => (
            <StaticImage
              src="../images/careers/handshake-emoji.png"
              alt="Handshake emoji"
              placeholder="blurred"
            />
          )}
          title="Together We Go Further"
          content="You believe that while each have individual responsibilities, we're all in this together and our collective success is most important."
        />
        <CareersAttributes
          Image={() => (
            <StaticImage
              src="../images/careers/smile-emoji.png"
              alt="Smile emoji"
              placeholder="blurred"
            />
          )}
          title="Friendly"
          content="You like making people smile."
        />
        <CareersAttributes
          Image={() => (
            <StaticImage
              src="../images/careers/explosion-emoji.png"
              alt="Explosion emoji"
              placeholder="blurred"
            />
          )}
          title="Action Oriented"
          content="You are a self-starter who sees and opportunity and pursues it."
        />
        <CareersAttributes
          Image={() => (
            <StaticImage
              src="../images/careers/muscle-emoji.png"
              alt="Muscle emoji"
              placeholder="blurred"
            />
          )}
          title="Confident"
          content="You are not afraid to speak up and you believe in yourself when presented a challenge."
        />
      </Box>

      <WorkingWithUs />
      {!noOpenPositions ? (
        <NoOpenPositions />
      ) : (
        <OpenPositions positions={positions} />
      )}

      <EqualOpportunity />
    </Layout>
  )
}

export default CareersIndex
export const pageQuery = graphql`
  query CareersIndexQuery {
    allContentfulJobPost {
      nodes {
        id
        title
        slug
        childContentfulJobPostDescriptionTextNode {
          childMarkdownRemark {
            html
            id
          }
        }
        childContentfulJobPostResponsibilitiesTextNode {
          childrenMarkdownRemark {
            html
            id
          }
        }
        childrenContentfulJobPostQualificationsTextNode {
          childrenMarkdownRemark {
            html
            id
          }
        }
        linkedInUrl {
          linkedInUrl
        }
      }
    }
  }
`

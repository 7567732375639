import React from "react"
import { Heading, Box, Flex, Text, Link } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"

const styles = {
  flex: {
    flexDirection: "column",
    alignItems: "center",
  },
  box: {
    borderBottom: "1px solid #E0E0E0",
    width: { base: "327px", md: "327px", lg: "658px" },
    marginTop: { base: "23px", md: "23px", lg: "30px" },
  },
  heading: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#1B263F",
    marginBottom: "24px",
  },
  "heading-box": {
    marginBottom: "24px",
  },
  "link-box": {
    marginBottom: "24px",
  },
  text: {
    color: "#3399FF",
  },
}
const OpenPositions = ({ positions }) => {
  const positionTitles = positions.map(position => (
    <Box sx={styles["link-box"]}>
      <Link as={GatsbyLink} to={`/careers/${position.slug}`}>
        <Text sx={styles.text}>{position.title}</Text>
      </Link>
    </Box>
  ))

  return (
    <Flex sx={styles.flex}>
      <Box sx={styles.box}>
        <Heading as="h4" sx={styles["heading-box"]}>
          Open Positions
        </Heading>
        {positionTitles}
      </Box>
    </Flex>
  )
}

export default OpenPositions
